import React from 'react'
import Item from './featured-item'
import { StickyContainer, Sticky } from 'react-sticky';
import cx from 'classnames';


class FeaturesScroller extends React.Component {
  constructor(props) {
    super(props);
  }

  isSmallerThan = (pix) => {
    if (typeof document !== 'undefined') {
      return document.documentElement.clientWidth < pix;
    }
    return false;
  }

  render() {
    const {
      mainTitle,
      subTitle,
      features,
    } = this.props;
    return (
      <StickyContainer>
        <section className="prodfeat  pad">
          <div className="row expanded collapse">
            {/* Title */}
            <div className="small-12 large-push-2 large-4 xlarge-3 huge-4 columns">
              <div className="row expanded">
                <div className="small-12 columns">
                  {!this.isSmallerThan(1200) ? (
                    <Sticky topOffset={0} bottomOffset={50}>
                      {({ style, isSticky }) => (
                        <div className={cx("prodfeat-title mpad", { ["sticky"]: isSticky })} style={style}>
                          {subTitle && <em>{subTitle}</em>}
                          <h2 className="dark">{mainTitle}</h2>
                        </div>
                      )}
                    </Sticky>
                  ) : (
                      <div className="prodfeat-title mpad">
                        {subTitle && <em>{subTitle}</em>}
                        <h2 className="dark">{mainTitle}</h2>
                      </div>
                    )}
                </div>
              </div>
            </div>

            {/* Features List */}
            <div className="small-12 large-pull-1 large-4 columns">
              <div className="row expanded">
                <div className="small-12 columns">
                  <div className="prodfeat-list prodfeat-scroller-item">
                    {features.map(feature => (
                      <Item
                        icon={feature.icon}
                        heading={feature.heading}
                        content={feature.content}
                        key={feature.heading}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </StickyContainer>
    );
  }
}

export default FeaturesScroller




