import React from 'react'
import ornamentLeft from '../../../images/ornament-left.svg'
import ornamentRight from '../../../images/ornament-right.svg'
import { FormattedMessage } from 'react-intl'

const TextBlock = ({ content, ornament}) => (
    <section className="textblock bg-white">
        {/* Ornament */}
        { ornament && (
            <div className={`ornament-${ornament == 'left' ? 'left' : 'right'}`}>
                <img src={ornament == 'left' ? ornamentLeft : ornamentRight} alt="Ornament"/>
            </div>
        )}

        {/* Content */}
        <div className="row expanded">
            <div className="small-12 medium-push-1 medium-10 large-push-2 large-8 xlarge-push-3 xlarge-6 columns">
                <div className="textblock-content pad mpad">
                    <p className="dark"><FormattedMessage id={content}/></p>
                </div>
            </div>
        </div>
    </section>
)

export default TextBlock



