import React from 'react'
import Header from '../../components/global/header/header'
import Hero from '../../components/heros/alternate'
import TextBlock from '../../components/global/textblock/standard'
import CallToAction from '../../components/global/cta'
import MainContent from '../../components/products/main-content'
import FeaturesScroller from '../../components/products/features-scroller'
import Features from '../../components/products/features'
import Footer from '../../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'
import { withIntl } from '../../i18n'
import { FormattedMessage } from 'react-intl'

// Images
import ctaImage from '../../images/7Z8A0083_Output.jpg'
import retail from "../../images/business-solutions/retail.jpg"

class ProductPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactOpen: false,
        }
    }

    toggleContact = () => {
        this.setState({
            contactOpen: !this.state.contactOpen
        });
        
        document.body.classList.toggle('noscroll');
    }

    componentDidMount() {
        if(document.body.classList.contains('noscroll')) {
            document.body.classList.remove('noscroll');
        }
    }

    render() {
        return (<></>);
        return (
            <PageTransition
                defaultStyle={{
                transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
                position: 'relative',
                width: '100%',
                opacity: 0,
                maxWidth: '2000px'

                }}
                transitionStyles={{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                    exiting: { opacity: 0, }
                }}
                
                transitionTime={1250}
            >
                <Header
                    headerStyle="std"
                    contactOpen={this.state.contactOpen}
                    action={() => this.toggleContact()}
                    {...this.props }
                />

                <Hero
                    featImage={retail}
                    pageTitleSub="retail"
                    pageTitleMain="field_sales"
                />

                <TextBlock content="a_multi_asset_trading_platform_para" />


                <div className="container financial-trading">

                    <div className="row">
                        <div className="col-xs-12">
                            <h4>
                                <FormattedMessage id="ats_multi_asset_platform"/>
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                <FormattedMessage id="core_feed_Integration_framework"/>
                            </p>

                            <div className="row financial-diagram-box">
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="market_data"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="execution_venues"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="stp"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="post_trade"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="trade_clearing"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="seperator-box left">
                                <p>
                                    <FormattedMessage id="core_services_framework"/>
                                </p>

                                <div className="row financial-diagram-box">
                                    <div className="col-sm-6">
                                        <ul>
                                            <li>
                                                <FormattedMessage id="workflow_engine"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="user_management"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="order_management"/>
                                            </li>

                                            <li className="colspan-stretch">
                                                <FormattedMessage id="virtual_golden_source"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul>
                                            <li>
                                                <FormattedMessage id="real_time_auditing"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="order_routing"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="system_management"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="seperator-box right">
                                <p>
                                    <FormattedMessage id="optional_modules"/>
                                </p>

                                <div className="row lift-up financial-diagram-box lift-bottom">
                                    <div className="col-sm-4">
                                        <ul>
                                            <li>
                                                <FormattedMessage id="request_for_quote"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-4">
                                        <ul>
                                            <li>
                                                <FormattedMessage id="multi_asset_trading"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-4">
                                        <ul>
                                            <li>
                                                <FormattedMessage id="risk_management"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row financial-diagram-box lift-top">
                                    <div className="col-sm-6">
                                        <ul>
                                            <li>
                                                <FormattedMessage id="best_execution"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="CLOB_IDE"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="matching_engine"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul>
                                            <li>
                                                <FormattedMessage id="trade_reconstruction"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="auctions"/>
                                            </li>

                                            <li>
                                                <FormattedMessage id="credit_matrix"/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                <FormattedMessage id="core_distribution_service"/>
                            </p>

                            <div className="row financial-diagram-box">
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="jms_broker"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="permissioning"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="mobile_devices"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="trading_api"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul>
                                        <li>
                                            <FormattedMessage id="streaming"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 sdk-apps">
                            <p>
                                <FormattedMessage id="application"/>
                            </p>

                            <div className="row">
                                <div className="col">
                                    <ul>
                                        <li>
                                            <i className="fa fa-connectdevelop"/>
                                            <FormattedMessage id="buy_side"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>
                                            <i className="fa fa-connectdevelop"/>
                                            <FormattedMessage id="brokers"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>
                                            <i className="fa fa-connectdevelop"/>
                                            <FormattedMessage id="traders"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>
                                            <i className="fa fa-connectdevelop"/>
                                            <FormattedMessage id="admin_support"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>
                                            <i className="fa fa-connectdevelop"/>
                                            <FormattedMessage id="mobile_devices"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>
                                            <i className="fa fa-connectdevelop"/>
                                            <FormattedMessage id="activity_monitors"/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>
                                            <i className="fa fa-connectdevelop"/>
                                            <FormattedMessage id="sales"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <CallToAction
                    image={ctaImage}
                    heading="tailor_made_perfect_fit"
                    content="lets_get_in_touch"
                    buttonLabel="contact_us"
                    action={() => this.toggleContact()}
                />

                <Footer />
            </PageTransition>
        )
    }
}

export default withIntl(ProductPage);




// ./src/pages/solutions/financials.jsx