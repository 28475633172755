import React from "react";
import Slider from "../sliders/product-slider/slider";
import Equalizer from "react-equalizer";

import ornament from "../../images/logo-half.svg";
import { FormattedMessage } from "react-intl";

class MainContent extends React.Component {
	constructor(props) {
		super(props);
	}

	getNodes(equalizerComponent, equalizerElement) {
		return [this.refs.thisThing, this.refs.thatThing];
	}

	render() {

        const styleOverride = this.props.noPadding ? {padding:0} : {}; 

		return (
			<section className='prodmain' style={styleOverride}>
				{!this.props.hideOrnament && (
					<div className="prodmain-ornament">
						<img src={ornament} alt="Ornament" />
					</div>
				)}
				{/* Content */}
				<Equalizer
					nodes={this.getNodes.bind(this)}
					className="row expanded collapse"
					byRow={false}
					property="height"
					enabled={() => window.matchMedia("(min-width: 1200px)").matches}
				>
					{/* Slider */}
					<div className="small-12 large-5 columns no-print">
						<div className="prodmain-slider" ref="thisThing">
							<Slider images={this.props.featImage} />
						</div>
					</div>

					{/* Content */}
					<div className="small-12 large-pull-1 large-5 columns print-platform__content">
						<div className="row expanded">
							<div className="small-12 columns">
								<div className="prodmain-content pad-top mpad pad-bot" ref="thatThing">
									<div className="prodmain-content__body">
										{/* Sub Title */}
										<em>
											<FormattedMessage id={this.props.subTitle} />
										</em>

										{/* Main Title */}
										<h2 className="dark heading">
											<FormattedMessage id={this.props.mainTitle} />
										</h2>

										{/* Body Copy: 1 */}
										<p className="dark">
											<FormattedMessage id={this.props.bodyCopy1} />
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Equalizer>
			</section>
		);
	}
}

export default MainContent;




