import React from 'react'


const MouseIcon = () => (
	
	<svg className="scroller" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px">
		<path d="M14,23h-4 c-2.8000002,0-5-2.2000008-5-5V6c0-2.8,2.1999998-5,5-5h4c2.7999992,0,5,2.2,5,5v12C19,20.7999992,16.7999992,23,14,23z"/>
		<line x1="12" x2="12" y1="7" y2="11"/>
	</svg>
)



export default MouseIcon



