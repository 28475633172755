import React from 'react'
import Item from './featured-item'
import { StickyContainer, Sticky } from 'react-sticky';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl'


class Features extends React.Component {
    constructor(props) {
        super(props);
    }

    isSmallerThan = (pix) => {
        if (typeof document !== 'undefined') {
            return document.documentElement.clientWidth < pix;
        }
        return false;
    }

    render() {
        return (
            <StickyContainer>
                <section className="prodfeat pad">
                    <div className="row expanded collapse">
                        {/* Title */}
                        <div className="small-12 medium-push-1 medium-5 large-push-2 large-4 xlarge-3 huge-2 columns print-prodfeat__title">
                            <div className="row expanded">
                                <div className="small-12 columns print-sticky">
                                    {!this.isSmallerThan(760) ? (
                                        <Sticky topOffset={0} bottomOffset={50}>
                                        {({ style, isSticky }) => (
                                            <div className={cx("prodfeat-title mpad", { [ "sticky" ]: isSticky})} style={style}>
                                                <em><FormattedMessage id={this.props.subTitle}/></em>
                                                <h2 className="dark"><FormattedMessage id={this.props.mainTitle}/></h2>
                                            </div>
                                        )}
                                        </Sticky>
                                    ) : (
                                        <div className="prodfeat-title mpad">
                                            <em><FormattedMessage id={this.props.subTitle}/></em>
                                            <h2 className="dark"><FormattedMessage id={this.props.mainTitle}/></h2>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Features List */}
                        <div className="small-12 medium-pull-1 medium-5 large-pull-1 large-6 columns">
                            <div className="row expanded">
                                <div className="small-12 columns">
                                    <div className="prodfeat-list">
                                        {this.props.features.map(feature => (
                                            <Item
                                                icon={feature.icon}
                                                heading={feature.heading}
                                                content={feature.content}
                                                key={feature.heading}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </StickyContainer>
        );
    }
}

export default Features




