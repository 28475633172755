import React from 'react'
import Slider from "react-slick";
import Slide from './slide'


class ProductSlider extends React.Component {


    constructor(props) {
        super(props);
    }


    render() {
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            fade: true,
            speed: 3000,
            autoplay: true,
            autoplaySpeed: 7000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <Slider {...settings} className="prodmain-slider">
                {this.props.images.map(image => {
                  return ( <Slide 
                        key={image}
                        image={image}
                  /> )
                })}
               
            
            </Slider>
        );
    }
}

export default ProductSlider




