import React from 'react'
import { FormattedMessage } from 'react-intl'

const FeaturedItem = ({ icon, heading, content }) => (
  <div className="prodfeat-list__item mpad">

    {/* Icon */}
    {icon &&
      <div className="prodfeat-list__item--icon">
        <img src={icon} alt="Icon" />
      </div>
    }

    {/* Heading */}
      <div className="prodfeat-list__item--content">
        {heading &&
          <p className="dark prodfeat-list__item--content__title"><FormattedMessage id={heading}/></p>
        }
        {content &&
          <p className="grey"><FormattedMessage id={content}/></p>
        } 
      </div>
  </div>
);

export default FeaturedItem




