import React from 'react'

const ProductSlider = ({image}) => (
    <div className="prodmain-slider__slide" key={image}>
        <img src={image} alt="Product Image"/>
    </div>
);

export default ProductSlider




